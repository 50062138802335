(function($) {
    'use strict';

    $('.c-offcanvas__back, .c-navicon__box').on('click', function() {
        $('.menu__item, .menu__item > span').removeClass('is-active');
        $('body').removeClass('sub-menu-is-active');
    });
    
    $('.c-offcanvas__booking-activator').on('click', function() {
        $('.c-offcanvas__sidebar').addClass('is-active');
    }); 
    
    $('.c-offcanvas__booking-back, .c-navicon__box').on('click', function() {
        $('.c-offcanvas__sidebar').removeClass('is-active');
    });

    $('.c-nav-header--contact').on('click', function() {
        $(this).toggleClass('is-active');
    });

    $('.c-navicon').on('click', function () {
        setTimeout(function() {
            $('body').trigger('revealOffcanvas');
        }, 50);
    });

    /**
     * Activate booking modal
     */

    $('.c-nav-header .menu__item:last-of-type').click(function() {
        $('html, body').addClass('booking-modal--is-active');
    });

    $('.c-booking-modal__close').click(function() {
        $('html, body').removeClass('booking-modal--is-active');
    });

    $('.menu__item--has-children').append("<span></span>");

    const menuItem = $('.menu__item--has-children');

    for(let i = 0; i < menuItem.length; i++) {
        menuItem[i].addEventListener('click', function() {
            menuItem.not($(this)).removeClass('is-active');
            menuItem.not($(this)).parent(".menu__item--has-children").removeClass('is-active');
            $(this).toggleClass("is-active");
            $(this).parent(".menu__item--has-children").toggleClass("is-active");
            $('body').toggleClass('sub-menu-is-active');
        });
    }

    if (window.location.href.indexOf("/middlesbrough/") > -1) {
        $('body').addClass('t-middlesbrough');
    }

    if (window.location.href.indexOf("/leigh-manchester/") > -1) {
        $('body').addClass('t-leigh-manchester');
    }

    $(".t-middlesbrough .c-navicon").click(function(){
        $('.c-nav-primary .menu__item--has-children:nth-of-type(1), .c-nav-primary .menu__item--has-children:nth-of-type(1) > span ').addClass("is-active");
    });

    $(".t-leigh-manchester .c-navicon").click(function(){
        $('.c-nav-primary .menu__item--has-children:nth-of-type(2), .c-nav-primary .menu__item--has-children:nth-of-type(2) > span ').addClass("is-active");
    });

    // var clampSelectors = [
    //     {
    //         cssSelector: '.c-grid-filter--cta .c-grid-filter__grid .c-grid-filter__item .c-cta--two-column .c-cta__text p',
    //         lineLength: 3
    //     }
    // ];

    // $.each(clampSelectors, function(index, clamp) {
    //     $(clamp.cssSelector).each(function(index, element) {
    //         $clamp(element, {clamp: clamp.lineLength});
    //     });
    // });


})(jQuery);

